import React, { useState } from 'react';
import Image from 'next/image';
import APP_IMAGES from '../../constants/appImages';
import { TESTIMONALS_DATA } from '../../constants/data';

const Testimonal = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const visibleTestimonials =
    currentIndex === TESTIMONALS_DATA.length - 1
      ? [TESTIMONALS_DATA[currentIndex], TESTIMONALS_DATA[0]]
      : TESTIMONALS_DATA.slice(currentIndex, currentIndex + 2);

  const handleDotClick = index => {
    setCurrentIndex(index % TESTIMONALS_DATA.length);
  };

  const desktop = (
    <section className="lg:bg-hero-testimonial lg:bg-no-repeat lg:bg-cover lg:bg-center w-full">
      <div className="mx-auto hidden md:block md:max-w-2xl lg:max-w-4xl xl:max-w-[1250px] 2xl:max-w-[1540px] px-10">
        <div className="md:my-16 lg:mt-8 lg:mb-20 lg:pb-20 flex flex-col xl:flex-row md:justify-start md:items-start xl:justify-between xl:items-center">
          <div className="xl:ml-0  2xl:max-w-[420px] md:mb-14">
            <p className=" text-primary font-semibold text-sm sm:text-lg sm:leading-7 uppercase">
              Testimonials
            </p>
            <h2 className="w-[176px] sm:w-[381px] text-4xl leading-10 lg:text-5xl lg:leading-[63px] xl:leading-[73px] font-semibold mb-14 xl:mr-4 2xl:mr-0">
              What people say about Us <span className="text-primary">.</span>
            </h2>
            <div className="hidden sm:flex items-center justify-start ml-4">
              {TESTIMONALS_DATA.map((employee, index) => (
                <span
                  key={employee.id}
                  className={`h-4 w-4 bg-primary rounded-full mr-3 ${index === currentIndex ? 'opacity-100' : 'opacity-30'
                    }`}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>
          </div>
          {/* Display just two card */}
          <div className="md:max-w-[750px]  lg:max-w-[998px] w-full flex justify-center gap-4 lg:gap-2 xl:gap-5 2xl:gap-6 overflow-hidden transition-all">
            {visibleTestimonials.map(employee => (
              <div
                key={employee.id}
                className="flex-1 mt-20 w-full md:w-[300px] lg:w-[415px] bg-white text-center px-4 pb-8 2xl:pb-12 lg:pt-20 xl:pt-28 border-primary border rounded-lg relative"
              >
                <div className="w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 xl:w-36 xl:h-36 bg-primary rounded-full absolute -top-[40px] sm:-top-[45px] md:-top-[60px] xl:-top-[70px] left-1/2 transform -translate-x-1/2">
                  {employee.profile ? (
                    <Image
                      src={employee.profile}
                      alt={employee.name}
                      className="rounded-full"
                    />
                  ) : (
                    <div className="text-2xl sm:text-3xl md:text-4xl xl:text-[56px] text-white font-semibold flex justify-center items-center h-full">
                      {employee.name
                        .split(' ')
                        .map(word => word[0].toUpperCase())
                        .join('')}
                    </div>
                  )}
                </div>
                <div className="mt-20 lg:mt-0 mb-6 text-center mx-auto flex items-center justify-center">
                  <Image src={APP_IMAGES.qouteImg} alt="quote" />
                </div>

                <h3 className="text-gray-darker font-semibold text-lg md:text-[22px] leading-tight mb-2">
                  {employee.name}
                </h3>
                <p className="text-sm leading-4 text-gray-dark mb-4 md:mb-8">
                  {employee.position}
                </p>
                <p className="text-gray-dark font-medium text-sm sm:text-lg leading-6 md:leading-7">
                  {employee.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );

  const mobile = (
    <div className="w-full px-4 max-w-[360px] mx-auto pt-16 sm:pt-20 overflow-hidden md:hidden">
      <div className="mb-4 text-left">
        <p className="text-primary font-semibold text-xs leading-5 uppercase">
          Testimonials
        </p>
        <h2 className="text-2xl leading-7 font-semibold">
          What people say
          <br />
          about Us <span className="text-primary">.</span>
        </h2>
      </div>
      <div className="flex flex-col items-center gap-y-16 overflow-hidden pt-14">
        {visibleTestimonials.map(employee => (
          <div
            key={employee.id}
            className="w-full max-w-[290px] mx-auto bg-white text-center px-3 pb-6 pt-16 border-primary border rounded-lg relative h-[380px] flex flex-col justify-between"
          >
            <div className="w-24 h-24 bg-primary rounded-full absolute -top-12 left-1/2 transform -translate-x-1/2">
              {employee.profile ? (
                <Image
                  src={employee.profile}
                  alt={employee.name}
                  className="rounded-full"
                />
              ) : (
                <div className="text-2xl text-white font-semibold flex justify-center items-center h-full">
                  {`${employee.name
                    .split(' ')
                    .map(word => word[0].toUpperCase())
                    .join('')}`}
                </div>
              )}
            </div>
            <div className="my-5 text-center w-12 mx-auto">
              <Image
                src={APP_IMAGES.qouteImg}
                alt="quote"
                className="text-center"
              />
            </div>

            <h3 className="text-gray-darker font-semibold text-base leading-tight mb-1">
              {employee.name}
            </h3>
            <p className="text-xs leading-4 text-gray-dark mb-3">
              {employee.position}
            </p>
            <p className="text-gray-dark font-medium text-xs leading-5 flex-grow">
              {employee.description}
            </p>
          </div>
        ))}
      </div>
      <div className="flex items-center justify-center mt-6">
        {TESTIMONALS_DATA.map((employee, index) => (
          <span
            key={employee.id}
            className={`w-3 h-3 bg-primary rounded-full mr-2 ${index === currentIndex ? 'opacity-100' : 'opacity-30'
              }`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {desktop}
      {mobile}
    </>
  );
};

export default Testimonal;
